import React, { Fragment } from "react";
import logo from "../../Assets/logo.jpg";
import background from "../../Assets/background.jpg";
import "./Maintenance.css";

const Maintenance = () => {
  return (
    <Fragment>
      <div className="container">
        <img src={background} className="image"  alt="wallpaper"/>
        <img src={logo} className="logo" alt="logo"/>
        <h1 className="h1">Site is undergoing maintenance</h1>
        <h3 className="h3">
          We will be available soon. Thank you for your patience!
        </h3>
        <h4 className="h4">
            © Keystone Asset Management 2023
        </h4>
      </div>
    </Fragment>
  );
};

export default Maintenance;
