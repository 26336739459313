import Maintenance from "./Components/Maintenance/Maintenance"

function App() {
  return (
    <div className="App">
      <Maintenance/>
    </div>
  );
}

export default App;
